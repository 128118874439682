import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';

import base from '../styles/base.module.css';
import main from '../styles/main.module.css';
import theme from '../styles/theme.module.css';

import Feed from '../components/insights/insightsFeed';
import Layout from '../components/layout';
import Clear from '../images/clear.svg';

/**
 * Insights page component. Parent component.
 * @component
 *
 * @param   {string} location - URL data object
 * @returns {component}       - <Insights location={string} />
 */

const Insights = ({ location }) => {
  const pageDescription =
    'News, events, and member engagement insights. Get the latest in Decision Point news, events, and member engagement insights.';
  const pageTitle = 'Insights';
  const pageSubtext =
    'Advice, true stories, expert insights, and everything else health care.';
  const types = ['News', 'Events', 'Webinars', 'Guides', 'Blog'];
  const [activeType, setActiveType] = useState('all');
  const { allContentfulInsights } = useStaticQuery(graphql`
    query {
      allContentfulInsights(sort: { fields: date, order: DESC }) {
        edges {
          node {
            superheader {
              childMarkdownRemark {
                html
              }
            }
            header {
              childMarkdownRemark {
                html
              }
            }
            heroImage {
              fluid(maxHeight: 150, quality: 80, resizingBehavior: FILL) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            hero {
              childMarkdownRemark {
                excerpt(format: HTML, pruneLength: 120, truncate: true)
                html
                rawMarkdownBody
              }
            }
            type
            slug
            summary {
              childMarkdownRemark {
                excerpt(format: HTML, pruneLength: 200, truncate: true)
                html
              }
            }
            content {
              childMarkdownRemark {
                excerpt(format: HTML, pruneLength: 120, truncate: true)
                html
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      location={location}
    >
      <section
        className={cx(
          base.flex,
          base.flexColumn,
          base.itemsCenter,
          base.justifyStart,
          base.pl3,
          base.pr3,
          base.w100,
          main.insightsHeader,
          theme.colorBorderGrey
        )}
        data-lean="true"
      >
        <h1
          className={cx(
            base.mb4,
            base.pb5,
            base.tc,
            base.w100,
            main.insightsTitle,
            theme.title0
          )}
        >
          {pageTitle}
        </h1>
        <p
          className={cx(
            base.mb4,
            base.mt0,
            base.tc,
            base.w100,
            main.insightsSubtitle
          )}
        >
          {pageSubtext}
        </p>
        <div
          className={cx(
            base.flex,
            base.itemsStart,
            base.justifyStart,
            base.pl3,
            base.pr3,
            base.w100,
            main.insightsTypes
          )}
        >
          <p
            className={cx(
              base.ma0,
              base.nowrap,
              base.pr2,
              base.pt1,
              theme.colorBorderGrey
            )}
          >
            Resource Types:
          </p>
          <ul
            className={cx(
              base.flex,
              base.flexWrap,
              base.itemsCenter,
              base.justifyStart,
              base.ma0,
              base.pa0,
              base.w100
            )}
          >
            {types.map(type => (
              <li
                key={type}
                className={cx(
                  base.flex,
                  base.flexWrap,
                  base.itemsCenter,
                  base.justifyStart,
                  main.insightsTypesItem,
                  theme.colorBorderGrey
                )}
                data-active={type === activeType}
              >
                <button
                  type="button"
                  className={cx(base.f7, base.ma0, base.noOutline, base.pa0)}
                  onClick={() => setActiveType(type)}
                >
                  {type}
                </button>
              </li>
            ))}
            {activeType !== 'all' && (
              <li
                className={cx(
                  base.flex,
                  base.flexWrap,
                  base.itemsCenter,
                  base.justifyCenter,
                  main.insightsTypesItem,
                  theme.colorBorderGrey
                )}
                data-round="true"
              >
                <button
                  type="button"
                  className={cx(base.f7, base.ma0, base.noOutline, base.pa0)}
                  onClick={() => setActiveType('all')}
                  aria-label="Clear type filter"
                >
                  <img src={Clear} alt="Clear" aria-hidden="true" />
                </button>
              </li>
            )}
          </ul>
        </div>
      </section>
      <section
        className={cx(
          base.flex,
          base.itemsStart,
          base.justifyCenter,
          base.pl3,
          base.pr3,
          base.w100,
          main.recentWrap
        )}
      >
        <Feed posts={allContentfulInsights.edges} activeType={activeType} />
      </section>
    </Layout>
  );
};

Insights.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default Insights;
